import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useEffect, useState } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import SleepChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SelectedTimeline from '../../../components/selected-timeline'


interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const StepsPanelContent = (props: IProps) => {
  const { selectedUser, userDetailData } = props

  const [dailyArrIndex, setDailyArrIndex] = useState<number>(0);
  const [weeklyArrStartIndex, setWeeklyArrStartIndex] = useState<number>(0);
  const [weeklyArrEndIndex, setWeeklyArrEndIndex] = useState<number>(0);
  const [formattedDailyArr, setFormattedDailyArr] = useState<any>([]);
  const [formattedWeeklyArr, setFormattedWeeklyArr] = useState<any>([]);


  const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>(null)
  const [chartOptions, setChartOptions] = useState<any>(defaultChartData)

  const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
  const [avgType, setAvgType] = useState<string | null>('')
  const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

  const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
  const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);

  // values for custom range
  const [startDateValue, setStartDateValue] = useState<Date | any>(null)
  const [endDateValue, setEndDateValue] = useState<Date | any>(null)

  // values for dates for weekly and monthly we agreed to get from the API, the end date for those 2 values will be today
  const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
  const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)

  // this track what date was selected when arrows were used. this one is to track the date when arrows where used for Today
  const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
  // true if right arrow clicked for daily, false if ledt arrow clicked, null if none of the arrows was clicked
  const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)

  // those 2 states will track the date range, when arrows buttons were used when a range fitler was selected (past week, custom etc)
  const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
  const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)

  const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)


  // Number of days to show on weekly chart
  const numOfWeeklyDays = 28


  ////
  // UseEffects
  ////
  // This sets the filter for all charts to weekly filter if a user is selected and no filter is currently selected
  useEffect(() => {
    if (selectedUser && !selectedFilter) {
      setSelectedFilter('weekly')
    }
  }, [selectedUser])

  // Function to check if the data is of type
  function isStepsData(data: any): data is Steps {
    return data !== null && typeof data !== "string";
  }

  // API CALL. get data when non-custom filter is selected
  // This sets up and formats the array of panel data
  useEffect(() => {
    if (userDetailData) {

      // Use this function when mapping 
      const daysArr = Object.keys(userDetailData.steps.daily).map(key => {
        const data = userDetailData.steps.daily[key];
        return { date: key, data: isStepsData(data) ? data : null };
      });

      const selectedDayIndex = daysArr.length - 1
      const selectedDailyDate = daysArr[selectedDayIndex].date

      const selectedWeekEndIndex = daysArr.length - 1
      const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays


      const weeklyData = daysArr.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)

      // Set up daily data
      setFormattedDailyArr(daysArr)
      setDailyArrIndex(selectedDayIndex)

      // This is for the display of the date in the panel for daily filter
      setSingleDayArrowsUsed(selectedDailyDate)

      // Set up weekly data
      setFormattedWeeklyArr(weeklyData)
      setWeeklyArrStartIndex(selectedWeekStartIndex)
      setWeeklyArrEndIndex(selectedWeekEndIndex)

    }
  }, [userDetailData])

  // Setting up chart for non custom filters
  useEffect(() => {

    if (userDetailData) {

      if (!selectedFilter || formattedDailyArr.length === 0 || dailyArrIndex === null
      ) {
        setChartOptions(defaultChartData) // initialize the state variable

      } else if (selectedFilter === 'weekly' && weeklyArrStartIndex !== null && weeklyArrEndIndex !== null) {
        const weeklyData = formattedDailyArr.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1)

        // This is for the display of hte dates in the panel for weekly filter
        setFilterButtonStartDate(weeklyData[0].date)
        setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)
        const categories = weeklyData.map((item: any) => {
          if (item.date) {
            return item?.date
          } else {
            return null
          }
        })

        const seriesSteps = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data
          } else {
            return null
          }
        })


        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              }
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories,
          },
          series: [
            {
              name: 'Steps',
              data: seriesSteps,
            },
          ],
        }
        setChartOptions(multidayChartData)
      }
      else if (selectedFilter === 'monthly') {

        const monthlyStepsAvgValues = Object.values(userDetailData.steps.monthly)
        const monthlyStepsAvgKeys = Object.keys(userDetailData.steps.monthly)

        setFilterButtonStartDate(monthlyStepsAvgKeys[0])
        setFilterButtonEndDate(monthlyStepsAvgKeys[monthlyStepsAvgKeys.length - 1])

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: monthlyStepsAvgKeys,
          },
          series: [
            {
              name: 'Average Steps',
              data: monthlyStepsAvgValues
            }
          ],
        }
        setChartOptions(multidayChartData)

      }

    } else {
      setChartOptions(defaultChartData)
    }
  }, [formattedDailyArr, weeklyArrStartIndex, weeklyArrEndIndex, dailyArrIndex, selectedFilter, singleDayArrowsUsed])


  ////
  // end of UseEffects
  ////

  // This monitors the display of the chart arrows
  useEffect(() => {

    if (userDetailData) {
      const lengthOfDataArr = Object.keys(userDetailData.steps.daily).length

      if (selectedFilter === 'daily') {
        if ((dailyArrIndex >= (lengthOfDataArr - 1))) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if ((dailyArrIndex <= 0)) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }
      else if (selectedFilter === 'weekly') {
        if (weeklyArrEndIndex >= (lengthOfDataArr - 1)) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if (weeklyArrStartIndex <= 0) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }

    }

  }, [dailyArrIndex, weeklyArrStartIndex, weeklyArrEndIndex, displayLeftArrow, displayRightArrow])


  const handleGetNext = () => {


    if (userDetailData) {
      const lengthOfDataArr = Object.keys(userDetailData.steps.daily).length

      if (selectedFilter === 'weekly') {

        if ((weeklyArrEndIndex + numOfWeeklyDays) <= lengthOfDataArr - 1) {
          setWeeklyArrEndIndex(weeklyArrEndIndex + numOfWeeklyDays);
          setWeeklyArrStartIndex(weeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setWeeklyArrEndIndex(lengthOfDataArr - 1);
          setWeeklyArrStartIndex((lengthOfDataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  const handleGetPrevious = () => {

    if (userDetailData) {

      if (selectedFilter === 'daily') {
        if ((dailyArrIndex - 1) >= 0) {
          setDailyArrIndex(dailyArrIndex - 1);
          setDisplayLeftArrow(true);
        }
        else {
          setDisplayLeftArrow(false);
          console.log("Reached beginning of data set")
        }

      } else if (selectedFilter === 'weekly') {
        if ((weeklyArrStartIndex - numOfWeeklyDays) >= 0) {
          setWeeklyArrStartIndex(weeklyArrStartIndex - numOfWeeklyDays);
          setWeeklyArrEndIndex(weeklyArrEndIndex - numOfWeeklyDays);

          setDisplayLeftArrow(true)
        } else {
          setWeeklyArrStartIndex(0);
          setWeeklyArrEndIndex(numOfWeeklyDays);

          setDisplayLeftArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            customRangeStartDate={startDateValue}
            customRangeEndDate={endDateValue}
            dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
            dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
            singleDay={singleDayArrowsUsed}
          />

          <ChartFilterButtons
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSingleDayArrowsUsed={setSingleDayArrowsUsed}
            setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
            setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
            startDateValue={startDateValue}
            setStartDateValue={setStartDateValue}
            endDateValue={endDateValue}
            setEndDateValue={setEndDateValue}
            setSingleDayDecrementDays={setSingleDayDecrementDays}
            setDateRangeDecrementDays={setDateRangeDecrementDays}
            setFilterButtonStartDate={setFilterButtonStartDate}
            setFilterButtonEndDate={setFilterButtonEndDate}
            setChartData={setChartOptions}
            showDailyFilter={false}
            showWeeklyFilter={true}
            showMonthlyFilter={true}
            showCustomFilter={false}
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            avgType={avgType}
            setAvgType={setAvgType}
            btnPressed={btnPressed}
            setBtnPressed={setBtnPressed}
          />

          <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item={true} xs={10}>
              <SleepChart chartOptions={chartOptions} />
            </Grid>

            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

export default StepsPanelContent