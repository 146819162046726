
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'


interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}


const WomanHealthPanelContent = (props: IProps) => {

  const { selectedUser, userDetailData } = props

  const [tableData, setTableData] = useState<any[]>([])
  const columns = [
    { name: 'Due Date', options: { filter: false, sort: true } },
    { name: 'Original Due Date', options: { filter: false, sort: true } },
    { name: 'Period Length', options: { filter: false, sort: true } },
    { name: 'Current Phase', options: { filter: false, sort: true } },
    { name: 'Current Phase Type', options: { filter: false, sort: true } },
    { name: 'Length Of Current Phase', options: { filter: false, sort: true } },
    { name: 'Cycle Length', options: { filter: false, sort: true } },
    { name: 'Predicted Cycle Length', options: { filter: false, sort: false } },
    { name: 'Is Predicted Cycle', options: { filter: false, sort: true } },
    { name: 'Pregnancy Cycle Start Date', options: { filter: false, sort: true } },
  ]
  const options = {
    viewColumns: false,
    filter: false,
    elevation: 0,
    selectableRows: 'none' as 'none',
    setTableProps: () => {
      return {
        size: 'small',
      }
    },
  }
  useEffect(() => {
    if (userDetailData) {

      const womensHealth = userDetailData.women
      const data: any[] = []

      let isPredictedCycle: any = String(womensHealth.ispredictedcycle)
      if (womensHealth.ispredictedcycle === true) {
        isPredictedCycle = <CheckCircleIcon color="success" />
      } else if (womensHealth.ispredictedcycle === false) {
        isPredictedCycle = <CancelIcon color="error" />
      }

      data.push([
        womensHealth.duedate,
        womensHealth.originalduedate,
        womensHealth.periodlength,
        womensHealth.currentphase,
        womensHealth.currentphasetype,
        womensHealth.daysuntilnextphase,
        womensHealth.cyclelength,
        womensHealth.predictedcyclelength,
        womensHealth.ispredictedcycle,
        womensHealth.pregnancycyclestartdate
      ])
      setTableData(data)
    }
  }, [userDetailData])


  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <MUIDataTable title="Women Health Data" data={tableData} columns={columns} options={options} />
      )}
    </>
  )
}
export default WomanHealthPanelContent
