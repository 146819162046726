import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'
import Box from '@mui/material/Box'
import SleepChart from '../../../components/chart'
import MUIDataTable from 'mui-datatables'

import Highcharts from 'highcharts';
import { NumberLocale } from 'yup/lib/locale'
import { object } from 'yup'


interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}

interface CustomPoint {
  y: number;
  additionalField: string
}


const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const SleepPanelContent = (props: IProps) => {
  const { selectedUser, userDetailData } = props

  const [dailyArrIndex, setDailyArrIndex] = useState<number>(0);
  const [weeklyArrStartIndex, setWeeklyArrStartIndex] = useState<number>(0);
  const [weeklyArrEndIndex, setWeeklyArrEndIndex] = useState<number>(0);
  const [formattedDailyArr, setFormattedDailyArr] = useState<any>([]);
  const [formattedWeeklyArr, setFormattedWeeklyArr] = useState<any>([]);


  const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>(null)
  const [sleepChartOptions, setSleepChartOptions] = useState<any>(defaultChartData)
  const [sleepQualityChartOptions, setSleepQualityChartOptions] = useState<any>(defaultChartData)

  const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
  const [avgType, setAvgType] = useState<string | null>('')
  const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

  const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
  const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);

  // values for custom range
  const [startDateValue, setStartDateValue] = useState<Date | any>(null)
  const [endDateValue, setEndDateValue] = useState<Date | any>(null)

  // values for dates for weekly and month we agreed to get from the API, the end date for those 2 values will be today
  const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
  const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)

  // this track what date was selected when arrows were used. this one is to track the date when arrows where used for Today
  const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
  // true if right arrow clicked for daily, false if left arrow clicked, null if none of the arrows was clicked
  const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)

  // those 2 states will track the date range, when arrows buttons were used when a range fitler was selected (past week, custom etc)
  const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
  const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)

  const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)

  // Number of days to show on weekly chart
  const numOfWeeklyDays = 28

  const [tableData, setTableData] = useState<any[]>([])

  const columns = [
    { name: 'Date', options: { filter: false, sort: true } },
    { name: 'Total Sleep', options: { filter: false, sort: true } },
    { name: 'Deep', options: { filter: false, sort: true } },
    { name: 'Light', options: { filter: false, sort: true } },
    { name: 'REM', options: { filter: false, sort: true } },
    { name: 'Awake', options: { filter: false, sort: true } },
    { name: 'Sleep Quality Score', options: { filter: false, sort: true } }
  ]

  const options = {
    textLabels: {
      body: {
        noMatch: "No data available for the date selected",
      },
    },
    viewColumns: false,
    filter: false,
    elevation: 0,
    selectableRows: 'none' as 'none',
    setTableProps: () => {
      return {
        size: 'small',
      }
    },
  }



  ////
  // UseEffects
  ////


  // This sets the filter for all charts to weekly filter if a user is selected and no filter is currently selected
  useEffect(() => {
    if (selectedUser && !selectedFilter) {
      setSelectedFilter('weekly')
    }
  }, [selectedUser])


  // Function to check if the data is of right type
  function isSleep(data: any): data is SleepData {
    return typeof data === 'object' && data !== null && 'sleeplevelsmap' in data;
  }


  // This sets up and formats the array of panel data
  useEffect(() => {
    if (userDetailData) {

      // Use this function when mapping data array
      const daysArr = Object.keys(userDetailData.sleep.daily).map(key => {
        const data = userDetailData.sleep.daily[key];
        return { date: key, data: isSleep(data) ? data : null };
      });


      const selectedDayIndex = daysArr.length - 1
      const selectedDailyDate = daysArr[selectedDayIndex].date

      const selectedWeekEndIndex = daysArr.length - 1
      const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays

      const weeklyData = daysArr.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)

      // Set up daily data
      setFormattedDailyArr(daysArr)
      setDailyArrIndex(selectedDayIndex)

      // This is for the display of the date in the panel for daily filter
      setSingleDayArrowsUsed(selectedDailyDate)

      // Set up weekly data
      setFormattedWeeklyArr(weeklyData)
      setWeeklyArrStartIndex(selectedWeekStartIndex)
      setWeeklyArrEndIndex(selectedWeekEndIndex)

    }
  }, [userDetailData])

  // Setting up chart for non custom filters
  useEffect(() => {

    if (userDetailData) {

      if (!selectedFilter || formattedDailyArr.length === 0 || dailyArrIndex === null
      ) {
        setSleepChartOptions(defaultChartData)
        setSleepQualityChartOptions(defaultChartData)

      } else if (selectedFilter === 'weekly' && weeklyArrStartIndex !== null && weeklyArrEndIndex !== null) {
        const weeklyData = formattedDailyArr.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1)

        // This is for the display of the dates in the panel for weekly filter
        setFilterButtonStartDate(weeklyData[0].date)
        setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)

        const categories = Object.values(weeklyData).map((item: any) => item.date)

        const seriesSleepQuality = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.sleepquality
          } else {
            return null
          }
        })


        const seriesDeepSleep = Object.values(weeklyData).map((item: any) => {
          if (item.data && item.data.deepsleepdurationinseconds != "-") {
            return { 
              y: item?.data?.deepsleepdurationinhours,
              additionalField: item?.data?.deepsleepduration
            }          

          } else {
            return { 
              y: 0,
              additionalField: "-"
            }   
          }
        })

        const seriesLightSleep = Object.values(weeklyData).map((item: any) => {
          if (item.data && item.data.lightsleepdurationinseconds !== "-") {
            return { 
              y: item?.data?.lightsleepdurationinhours,
              additionalField: item?.data?.lightsleepduration
            } 
          } else {
            return { 
              y: 0,
              additionalField: "-"
            }   
          }
        })
        const seriesREMSleep = Object.values(weeklyData).map((item: any) => {
          if (item.data && item.data.remsleepdurationinseconds !== "-") {
            return { 
             y: item?.data?.remsleepdurationinhours,
             additionalField: item?.data?.remsleepduration
            }
          } else {
            return { 
              y: 0,
              additionalField: "-"
            }   
          }
        })
        const seriesAwakeDuration = Object.values(weeklyData).map((item: any) => {

          if (item.data && item.data.awakedurationinseconds !== "-") {

            return { 
              y: item?.data?.awakedurationinhours,
              additionalField: item?.data?.awakeduration
            }
          } else {
            return { 
              y: 0,
              additionalField: "-"
            }   
          }
        })


        const multidaySleepChartData = {
          title: {
            text: 'Sleep Hours',
          },
          chart: {
            type: 'column',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              },
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories,
          },
          tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
              const additionalData = (this.point as any).additionalField; 
              return `${this.series.name}: ${additionalData}`   
            }
          },
          series: [
            {
              name: 'Deep',
              data: seriesDeepSleep
            },
            {
              name: 'Light',
              data: seriesLightSleep
            },
            {
              name: 'REM',
              data: seriesREMSleep,
            },
            {
              name: 'Awake',
              data: seriesAwakeDuration,
            },
          ],
        }

        const multiDaySleepQualityChartData = {
          ...defaultChartData,
          title: {
            text: 'Sleep Quality',
          },
          chart: {
            type: 'line',
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories,
          },
          series: [{
            ...defaultChartData.series,
            data: seriesSleepQuality,
            name: 'Sleep Quality ',
            color: '#81c784',
          }]
        }

        

        setSleepChartOptions(multidaySleepChartData)
        setSleepQualityChartOptions(multiDaySleepQualityChartData)
      }
      else if (selectedFilter === 'monthly') {

        const monthlySleepQualityKeys = Object.keys(userDetailData.monthly_avg_sleepquality.monthly)
        const monthlySleepQualityValues = Object.values(userDetailData.monthly_avg_sleepquality.monthly)

        const monthlySleepHoursAvgKeys = Object.keys(userDetailData.sleep.monthly)
        const monthlySleepHoursAvgValues = Object.values(userDetailData.sleep.monthly)

        setFilterButtonStartDate(monthlySleepQualityKeys[0])
        setFilterButtonEndDate(monthlySleepQualityKeys[monthlySleepQualityKeys.length - 1])

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: monthlySleepQualityKeys,
          },
          series: [
            {
              name: 'Average Sleep Quality',
              data: monthlySleepQualityValues
            },
            {
              name: 'Average Sleep Hours',
              data: monthlySleepHoursAvgValues
            }
          ],
        }
        setSleepQualityChartOptions(multidayChartData)

      }

    } else {
      setSleepChartOptions(defaultChartData)
      setSleepQualityChartOptions(defaultChartData)
    }
  }, [formattedDailyArr, weeklyArrStartIndex, weeklyArrEndIndex, dailyArrIndex, selectedFilter, singleDayArrowsUsed])

  // This monitors the display of the chart arrows
  useEffect(() => {

    if (userDetailData) {
      const lengthOfDataArr = Object.keys(userDetailData.sleep.daily).length

      if (selectedFilter === 'weekly') {
        if (weeklyArrEndIndex >= (lengthOfDataArr - 1)) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if (weeklyArrStartIndex <= 0) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }

    }

  }, [dailyArrIndex, weeklyArrStartIndex, weeklyArrEndIndex, displayLeftArrow, displayRightArrow])



  // Set up data for table
  useEffect(() => {
    if (userDetailData) {
      const data: any[] = []

      let reversedFormattedDailyArr: any[] = formattedDailyArr.slice().reverse();

      reversedFormattedDailyArr.forEach((item: any) => {
        if (item.data) {
          data.push([
            item.date,
            item.data.totalsleepduration,
            item.data.deepsleepduration,
            item.data.lightsleepduration,
            item.data.remsleepduration,
            item.data.awakeduration,
            String(item.data.sleepquality) + "%",
          ])
        }
      })
      setTableData(data)
    }
  }, [formattedDailyArr])


  const handleGetNext = () => {

    if (userDetailData) {
      const lengthOfDataArr = Object.keys(userDetailData.sleep.daily).length

      if (selectedFilter === 'daily') {

        if ((dailyArrIndex + 1) <= lengthOfDataArr - 1) {
          setDailyArrIndex(dailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      } else if (selectedFilter === 'weekly') {

        if ((weeklyArrEndIndex + 7) <= lengthOfDataArr - 1) {
          setWeeklyArrEndIndex(weeklyArrEndIndex + numOfWeeklyDays);
          setWeeklyArrStartIndex(weeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setWeeklyArrEndIndex(lengthOfDataArr - 1);
          setWeeklyArrStartIndex((lengthOfDataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }


  const handleGetPrevious = () => {

    if (userDetailData) {

      if (selectedFilter === 'daily') {
        if ((dailyArrIndex - 1) >= 0) {
          setDailyArrIndex(dailyArrIndex - 1);
          setDisplayLeftArrow(true);
        }
        else {
          setDisplayLeftArrow(false);
          console.log("Reached beginning of data set")
        }

      } else if (selectedFilter === 'weekly') {
        if ((weeklyArrStartIndex - numOfWeeklyDays) >= 0) {
          setWeeklyArrStartIndex(weeklyArrStartIndex - numOfWeeklyDays);
          setWeeklyArrEndIndex(weeklyArrEndIndex - numOfWeeklyDays);

          setDisplayLeftArrow(true)
        } else {
          setWeeklyArrStartIndex(0);
          setWeeklyArrEndIndex(numOfWeeklyDays);

          setDisplayLeftArrow(false)
          console.log("Reached end of data set")
        }
      }

    }
  }




  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            customRangeStartDate={startDateValue}
            customRangeEndDate={endDateValue}
            dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
            dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
            singleDay={singleDayArrowsUsed}
          />

          <ChartFilterButtons
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSingleDayArrowsUsed={setSingleDayArrowsUsed}
            setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
            setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
            startDateValue={startDateValue}
            setStartDateValue={setStartDateValue}
            endDateValue={endDateValue}
            setEndDateValue={setEndDateValue}
            setSingleDayDecrementDays={setSingleDayDecrementDays}
            setDateRangeDecrementDays={setDateRangeDecrementDays}
            setFilterButtonStartDate={setFilterButtonStartDate}
            setFilterButtonEndDate={setFilterButtonEndDate}
            setChartData={setSleepChartOptions}
            showDailyFilter={false}
            showWeeklyFilter={true}
            showMonthlyFilter={true}
            showCustomFilter={false}
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            avgType={avgType}
            setAvgType={setAvgType}
            btnPressed={btnPressed}
            setBtnPressed={setBtnPressed}
          />

          <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            {selectedFilter !== 'monthly' && selectedFilter !== 'custom' && (
              <Grid item={true} xs={10}>
                <SleepChart chartOptions={sleepChartOptions} />
                <Box sx={{ marginTop: '10px' }} />
                <SleepChart chartOptions={sleepQualityChartOptions} />
              </Grid>
            )}

            {selectedFilter === 'custom' && (
              <Grid item={true} xs={10}>
                <SleepChart chartOptions={sleepQualityChartOptions} />
              </Grid>
            )}

            {selectedFilter === 'monthly' && (
              <Grid item={true} xs={10}>
                <SleepChart chartOptions={sleepQualityChartOptions} />
              </Grid>
            )}


            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
          {
            selectedFilter !== 'custom' && (
              <MUIDataTable title="" data={tableData} columns={columns} options={options} />
            )
          }
        </>
      )}
    </>
  )
}

export default SleepPanelContent