import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'
import MUIDataTable from 'mui-datatables'
import BloodPressureChart from '../../../components/chart'

interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const BloodPressurePanelContent = (props: IProps) => {
  const { selectedUser, userDetailData } = props

  const [dailyArrIndex, setDailyArrIndex] = useState<number>(0);
  const [weeklyArrStartIndex, setWeeklyArrStartIndex] = useState<number>(0);
  const [weeklyArrEndIndex, setWeeklyArrEndIndex] = useState<number>(0);
  const [formattedDailyArr, setFormattedDailyArr] = useState<any>([]);
  const [formattedWeeklyArr, setFormattedWeeklyArr] = useState<any>([]);


  const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>(null)
  const [chartOptions, setChartOptions] = useState<any>(defaultChartData)

  const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
  const [avgType, setAvgType] = useState<string | null>('')
  const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

  const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
  const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);

  // values for custom range
  const [startDateValue, setStartDateValue] = useState<Date | any>(null)
  const [endDateValue, setEndDateValue] = useState<Date | any>(null)

  // values for dates for weekly and month we agreed to get from the API, the end date for those 2 values will be today
  const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
  const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)

  // this track what date was selected when arrows were used. this one is to track the date when arrows where used for Today
  const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
  // true if right arrow clicked for daily, false if left arrow clicked, null if none of the arrows was clicked
  const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)

  // those 2 states will track the date range, when arrows buttons were used when a range fitler was selected (past week, custom etc)
  const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
  const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)

  const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)

  const [tableData, setTableData] = useState<any[]>([])

  const columns = [
    { name: 'Measurement Time', options: { filter: false, sort: true } },
    { name: 'Systolic', options: { filter: false, sort: true } },
    { name: 'Diastolic', options: { filter: false, sort: true } },
    { name: 'Source Type', options: { filter: false, sort: true } },
    { name: 'Pulse', options: { filter: false, sort: true } },
    { name: 'MAP', options: { filter: false, sort: true } }
  ]

  const options = {
    textLabels: {
      body: {
        noMatch: "No data available for the date selected",
      },
    },
    viewColumns: false,
    filter: false,
    elevation: 0,
    selectableRows: 'none' as 'none',
    setTableProps: () => {
      return {
        size: 'small',
      }
    },
  }


  ////
  // UseEffects
  ////

  // This sets the filter for all charts to weekly filter if a user is selected and no filter is currently selected
  useEffect(() => {
    if (selectedUser && !selectedFilter) {
      setSelectedFilter('daily')
    }
  }, [selectedUser])

  // Function to check if the data is of right type
  function isBloodPressure(data: any): data is BloodPressureMeasurement[] {
    return Array.isArray(data) && data !== null;
  }

  function isInteger(value: any): boolean {
    return typeof value === 'number' && Number.isInteger(value);
}


  // This sets up and formats the array of panel data
  useEffect(() => {
    if (userDetailData) {

      // Use this function when mapping data array
      const daysArr = Object.keys(userDetailData.blood_pressure.daily).map(key => {

        let data = null;

        if (userDetailData.blood_pressure.daily[key] !== "-") {
          data = userDetailData.blood_pressure.daily[key]
        }
        return { date: key, data: data };
      });

      const selectedDayIndex = daysArr.length - 1
      const selectedDailyDate = daysArr[selectedDayIndex].date

      // Set up daily data
      setFormattedDailyArr(daysArr)
      setDailyArrIndex(selectedDayIndex)

      // This is for the display of the date in the panel for daily filter
      setSingleDayArrowsUsed(selectedDailyDate)


    }
  }, [userDetailData])



  // Setting up chart for non custom filters
  useEffect(() => {

    if (userDetailData) {

      if (!selectedFilter || formattedDailyArr.length === 0 || dailyArrIndex === null
      ) {
        setChartOptions(defaultChartData) // initialize the state variable

      } else if (selectedFilter === 'daily') {

        const dailyData = formattedDailyArr[dailyArrIndex]?.data;
        const dailyDate = formattedDailyArr[dailyArrIndex]?.date;

        setSingleDayArrowsUsed(dailyDate)

        const data: any[] = []
        let localMeasurementTime;

        if (dailyData) {
          dailyData.forEach((item: any) => {

            if (isInteger(item.measurementtime)){
              const measurementTimeInMilli = item.measurementtime * 1000
              
              localMeasurementTime = new Date(measurementTimeInMilli).toLocaleTimeString()
            } else {
              localMeasurementTime = item.measurementtime
            }


            data.push([
              localMeasurementTime,
              item.systolic,
              item.diastolic,
              item.sourceType,
              item.pulse,
              item.mapformula
            ])

          })
        }

        

        setTableData(data)
      }

    } else {
      setTableData([])
    }
  }, [formattedDailyArr, dailyArrIndex, selectedFilter, singleDayArrowsUsed])


  // This monitors the display of the chart arrows
  useEffect(() => {

    if (userDetailData) {
      const lengthOfO2DataArr = Object.keys(userDetailData.blood_pressure.daily).length

      if (selectedFilter === 'daily') {
        if ((dailyArrIndex >= (lengthOfO2DataArr - 1))) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if ((dailyArrIndex <= 0)) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }

    }

  }, [dailyArrIndex, displayLeftArrow, displayRightArrow])


  ////
  // end of UseEffects
  ////


  const handleGetNext = () => {


    if (userDetailData) {
      const lengthOfDataArr = Object.keys(userDetailData.blood_pressure.daily).length

      if (selectedFilter === 'daily') {

        if ((dailyArrIndex + 1) <= lengthOfDataArr - 1) {
          setDailyArrIndex(dailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      }
    }
  }


  const handleGetPrevious = () => {

    if (userDetailData) {

      if (selectedFilter === 'daily') {
        if ((dailyArrIndex - 1) >= 0) {
          setDailyArrIndex(dailyArrIndex - 1);
          setDisplayLeftArrow(true);
        }
        else {
          setDisplayLeftArrow(false);
          console.log("Reached beginning of data set")
        }
      }
    }
  }

  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            customRangeStartDate={startDateValue}
            customRangeEndDate={endDateValue}
            dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
            dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
            singleDay={singleDayArrowsUsed}
          />

          <ChartFilterButtons
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSingleDayArrowsUsed={setSingleDayArrowsUsed}
            setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
            setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
            setStartDateValue={setStartDateValue}
            setEndDateValue={setEndDateValue}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            setSingleDayDecrementDays={setSingleDayDecrementDays}
            setDateRangeDecrementDays={setDateRangeDecrementDays}
            setFilterButtonStartDate={setFilterButtonStartDate}
            setFilterButtonEndDate={setFilterButtonEndDate}
            setChartData={setChartOptions}
            showDailyFilter={true}
            showWeeklyFilter={false}
            showMonthlyFilter={false}
            showCustomFilter={false}
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            avgType={avgType}
            setAvgType={setAvgType}
            btnPressed={btnPressed}
            setBtnPressed={setBtnPressed}
          />

          <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>

            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            {
              selectedFilter === 'daily' && (
                <MUIDataTable title="" data={tableData} columns={columns} options={options} />
              )
            }

            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>


        </>
      )}
    </>
  )
}

export default BloodPressurePanelContent