import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Form, Formik } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import FormikInput from '../../components/formik-input'
import { useSnackbar } from 'notistack'
import axios from 'axios'

interface IProps {
  setShowExportForm: Dispatch<SetStateAction<boolean>>
  user: any
}

const ExportForm = ({ setShowExportForm, user }: IProps) => {
  console.log("SelectedUser for Export: ", user)
  const { enqueueSnackbar } = useSnackbar()

  const handleExport = (values: { email: string }) => {
    // TODO send an API call here using the payload below
    setShowExportForm(false)


    const payload = {
      'mommy_first_name': user.first_name,
      'mommy_last_name': user.last_name,
      'mommy_email': user.email,
      'to_email': values.email,
    }
    console.log('payload for export: ', payload)

    axios
      .post(process.env.REACT_APP_API_URL + 'export-user', payload)
      .then((response) => {

        enqueueSnackbar('The data was exported successfully', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar('The data failed to export. Please reach out to Tech Support', { variant: 'error' })
      })
  }

  return (
    <Formik initialValues={{ email: '' }} onSubmit={handleExport}>
      {() => {
        return (
          <Form>
            <Grid container={true} spacing={2} alignItems="center">
              <Grid item={true} xs={4}>
                <FormikInput name="email" inputLabel="Email" variant="outlined" size="small" color="secondary" />
              </Grid>

              <Grid item={true} xs={4}>
                <Button variant="contained" color="secondary" type="submit" sx={{ height: '40px', marginTop: '8px' }}>
                  Export
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ExportForm
