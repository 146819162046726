import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useEffect, useState } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import SleepChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SelectedTimeline from '../../../components/selected-timeline'


interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const O2PanelContent = (props: IProps) => {
  const { selectedUser, userDetailData } = props

  const [o2DailyArrIndex, setO2DailyArrIndex] = useState<number>(0);
  const [o2WeeklyArrStartIndex, setO2WeeklyArrStartIndex] = useState<number>(0);
  const [o2WeeklyArrEndIndex, setO2WeeklyArrEndIndex] = useState<number>(0);
  const [formattedO2DailyArr, setFormattedO2DailyArr] = useState<any>([]);
  const [formattedO2WeeklyArr, setFormattedO2WeeklyArr] = useState<any>([]);


  const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>(null)
  const [chartOptions, setChartOptions] = useState<any>(defaultChartData)

  const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
  const [avgType, setAvgType] = useState<string | null>('')
  const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

  const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
  const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);

  // values for custom range
  const [startDateValue, setStartDateValue] = useState<Date | any>(null)
  const [endDateValue, setEndDateValue] = useState<Date | any>(null)

  // values for dates for weekly and monthly we agreed to get from the API, the end date for those 2 values will be today
  const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
  const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)

  // this track what date was selected when arrows were used. this one is to track the date when arrows where used for Today
  const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
  // true if right arrow clicked for daily, false if ledt arrow clicked, null if none of the arrows was clicked
  const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)

  // those 2 states will track the date range, when arrows buttons were used when a range fitler was selected (past week, custom etc)
  const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
  const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)

  const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)


  // Number of days to show on weekly chart
  const numOfWeeklyDays = 28

  ////
  // UseEffects
  ////
  // This sets the filter for all charts to weekly filter if a user is selected and no filter is currently selected
  useEffect(() => {
    if (selectedUser && !selectedFilter) {
      setSelectedFilter('daily')
    }
  }, [selectedUser])

  // Function to check if the data is of type O2Data
  function isO2Data(data: any): data is O2Data {
    return typeof data === 'object' && data !== null && 'timeoffsetsleepspo2' in data;
  }

  // API CALL. get data when non-custom filter is selected
  // This sets up and formats the array of panel data
  useEffect(() => {
    if (userDetailData) {

      // Use this function when mapping formattedO2DailyArr
      const o2DaysArr = Object.keys(userDetailData.o2.daily).map(key => {
        const data = userDetailData.o2.daily[key];
        return { date: key, data: isO2Data(data) ? data : null };
      });

      const selectedDayIndex = o2DaysArr.length - 1
      const selectedDailyDate = o2DaysArr[selectedDayIndex].date

      const selectedWeekEndIndex = o2DaysArr.length - 1
      const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays

      const weeklyData = o2DaysArr.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)

      // Set up daily data
      setFormattedO2DailyArr(o2DaysArr)
      setO2DailyArrIndex(selectedDayIndex)

      // This is for the display of the date in the panel for daily filter
      setSingleDayArrowsUsed(selectedDailyDate)

      // Set up weekly data
      setFormattedO2WeeklyArr(weeklyData)
      setO2WeeklyArrStartIndex(selectedWeekStartIndex)
      setO2WeeklyArrEndIndex(selectedWeekEndIndex)

    }
  }, [userDetailData])

  // Setting up chart for non custom filters
  useEffect(() => {

    if (userDetailData) {

      if (!selectedFilter || formattedO2DailyArr.length === 0 || o2DailyArrIndex === null
      ) {
        setChartOptions(defaultChartData) // initialize the state variable

      } else if (selectedFilter === 'daily') {

        const dailyData = formattedO2DailyArr[o2DailyArrIndex]?.data;
        const dailyDate = formattedO2DailyArr[o2DailyArrIndex]?.date

        setSingleDayArrowsUsed(dailyDate)

        const categories = isO2Data(dailyData)
          ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) =>
            new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
          : [];

        const series = isO2Data(dailyData)
          ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) => item?.value)
          : [];

        const chartDataForDaily = {
          ...defaultChartData,
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories
          },
          series: [
            {
              name: 'O2 Sats',
              data: series,
            },
          ],
        }

        setChartOptions(chartDataForDaily)
      } else if (selectedFilter === 'weekly' && o2WeeklyArrStartIndex !== null && o2WeeklyArrEndIndex !== null) {
        const weeklyData = formattedO2DailyArr.slice(o2WeeklyArrStartIndex, o2WeeklyArrEndIndex + 1)

        // This is for the display of hte dates in the panel for weekly filter
        setFilterButtonStartDate(weeklyData[0].date)
        setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)
        const categories = Object.values(weeklyData).map((item: any) => item.date)

        const seriesLowO2 = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.low
          } else {
            return null
          }
        })

        const seriesAvgO2 = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.avg
          } else {
            return null
          }
        })

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              }
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories,
          },
          series: [
            {
              name: 'Low',
              data: seriesLowO2,
            },
            {
              name: 'Average',
              data: seriesAvgO2,
            },
          ],
        }
        setChartOptions(multidayChartData)
      }
      else if (selectedFilter === 'monthly') {

        const monthlyO2AvgValues = Object.values(userDetailData.o2.monthly)
        const monthlyO2AvgKeys = Object.keys(userDetailData.o2.monthly)

        setFilterButtonStartDate(monthlyO2AvgKeys[0])
        setFilterButtonEndDate(monthlyO2AvgKeys[monthlyO2AvgKeys.length - 1])

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: monthlyO2AvgKeys,
          },
          series: [
            {
              name: 'Average',
              data: monthlyO2AvgValues
            }
          ],
        }
        setChartOptions(multidayChartData)

      }

    } else {
      setChartOptions(defaultChartData)
    }
  }, [formattedO2DailyArr, o2WeeklyArrStartIndex, o2WeeklyArrEndIndex, o2DailyArrIndex, selectedFilter, singleDayArrowsUsed])


  // API CALL. get data when custom filter is selected
  // useEffect(() => {

  //   if (selectedUser && selectedFilter && selectedFilter === 'custom' && startDateValue && endDateValue && numberOfUnits && avgType && btnPressed) {

  //     setBtnPressed(false)

  //     axios
  //       .post(process.env.REACT_APP_API_URL + 'filter-one', {
  //         firstName: selectedUser.first_name,
  //         lastName: selectedUser.last_name,
  //         email: selectedUser.email,
  //         panelType: 'heartrate',
  //         filterType: selectedFilter,
  //         customDates: {
  //           startDate: format(startDateValue, 'yyyy-MM-dd'),
  //           endDate: format(endDateValue, 'yyyy-MM-dd'),
  //         },
  //         avgType: avgType,
  //         numberOfUnits: numberOfUnits
  //       })
  //       .then((response) => {
  //         setCustomHeartrateData(response.data.data.custom_resting_heart_rate_averages)
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar('Something went wrong, and heartrate data did not load', { variant: 'error' })
  //       })
  //   }
  // }, [selectedFilter, selectedUser, startDateValue, endDateValue, numberOfUnits, avgType, btnPressed, enqueueSnackbar])


  // Set up chart for custom
  // useEffect(() => {

  //   if (customHeartrateData) {
  //     if (selectedFilter === 'custom') {


  //       let restingHeartRateAverages = [...customHeartrateData]

  //       const multidayChartData = {
  //         title: {
  //           text: '',
  //         },
  //         plotOptions: {
  //           series: {
  //             label: {
  //               connectorAllowed: false,
  //             },
  //           },
  //         },
  //         xAxis: {
  //           ...defaultChartData.xAxis,
  //           categories: restingHeartRateAverages.map((data) => data.timeframe),
  //         },
  //         series: [
  //           {
  //             name: 'Resting Heart Rate In Beats Per Minute',
  //             data: restingHeartRateAverages.map((data) => data.custom_resting_heart_rate_avg),
  //           }
  //         ],
  //       }
  //       setChartOptions(multidayChartData)

  //     }
  //   }

  // }, [customHeartrateData, selectedFilter])

  ////
  // end of UseEffects
  ////

  // This monitors the display of the chart arrows
  useEffect(() => {

    if (userDetailData) {
      const lengthOfO2DataArr = Object.keys(userDetailData.o2.daily).length

      if (selectedFilter === 'daily') {
        if ((o2DailyArrIndex >= (lengthOfO2DataArr - 1))) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if ((o2DailyArrIndex <= 0)) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }
      else if (selectedFilter === 'weekly') {
        if (o2WeeklyArrEndIndex >= (lengthOfO2DataArr - 1)) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if (o2WeeklyArrStartIndex <= 0) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }

    }

  }, [o2DailyArrIndex, o2WeeklyArrStartIndex, o2WeeklyArrEndIndex, displayLeftArrow, displayRightArrow])


  const handleGetNext = () => {


    if (userDetailData) {
      const lengthOfO2DataArr = Object.keys(userDetailData.o2.daily).length

      if (selectedFilter === 'daily') {

        if ((o2DailyArrIndex + 1) <= lengthOfO2DataArr - 1) {
          setO2DailyArrIndex(o2DailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      } else if (selectedFilter === 'weekly') {

        if ((o2WeeklyArrEndIndex + numOfWeeklyDays) <= lengthOfO2DataArr - 1) {
          setO2WeeklyArrEndIndex(o2WeeklyArrEndIndex + numOfWeeklyDays);
          setO2WeeklyArrStartIndex(o2WeeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setO2WeeklyArrEndIndex(lengthOfO2DataArr - 1);
          setO2WeeklyArrStartIndex((lengthOfO2DataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  const handleGetPrevious = () => {

    if (userDetailData) {

      if (selectedFilter === 'daily') {
        if ((o2DailyArrIndex - 1) >= 0) {
          setO2DailyArrIndex(o2DailyArrIndex - 1);
          setDisplayLeftArrow(true);
        }
        else {
          setDisplayLeftArrow(false);
          console.log("Reached beginning of data set")
        }

        console.log("o2DailyArrIndex: ", o2DailyArrIndex)

      } else if (selectedFilter === 'weekly') {
        if ((o2WeeklyArrStartIndex - numOfWeeklyDays) >= 0) {
          setO2WeeklyArrStartIndex(o2WeeklyArrStartIndex - numOfWeeklyDays);
          setO2WeeklyArrEndIndex(o2WeeklyArrEndIndex - numOfWeeklyDays);

          setDisplayLeftArrow(true)
        } else {
          setO2WeeklyArrStartIndex(0);
          setO2WeeklyArrEndIndex(numOfWeeklyDays);

          setDisplayLeftArrow(false)
          console.log("Reached end of data set")
        }
      }
      console.log("o2WeeklyArrStartIndex: ", o2WeeklyArrStartIndex)
      console.log("o2WeeklyArrEndIndex: ", o2WeeklyArrEndIndex)
    }
  }

  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            customRangeStartDate={startDateValue}
            customRangeEndDate={endDateValue}
            dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
            dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
            singleDay={singleDayArrowsUsed}
          />

          <ChartFilterButtons
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSingleDayArrowsUsed={setSingleDayArrowsUsed}
            setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
            setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
            startDateValue={startDateValue}
            setStartDateValue={setStartDateValue}
            endDateValue={endDateValue}
            setEndDateValue={setEndDateValue}
            setSingleDayDecrementDays={setSingleDayDecrementDays}
            setDateRangeDecrementDays={setDateRangeDecrementDays}
            setFilterButtonStartDate={setFilterButtonStartDate}
            setFilterButtonEndDate={setFilterButtonEndDate}
            setChartData={setChartOptions}
            showDailyFilter={true}
            showWeeklyFilter={true}
            showMonthlyFilter={true}
            showCustomFilter={false}
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            avgType={avgType}
            setAvgType={setAvgType}
            btnPressed={btnPressed}
            setBtnPressed={setBtnPressed}
          />

          <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item={true} xs={10}>
              <SleepChart chartOptions={chartOptions} />
            </Grid>

            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

export default O2PanelContent
