import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import HeartrateChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'


interface IProps {
  selectedUser: IUserInfo | null
  userDetailData: IUserDetailData | null
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const HeartratePanelContent = (props: IProps) => {
  const { selectedUser, userDetailData } = props

  const [heartrateDailyArrIndex, setHeartrateDailyArrIndex] = useState<number>(0);
  const [heartrateWeeklyArrStartIndex, setHeartrateWeeklyArrStartIndex] = useState<number>(0);
  const [heartrateWeeklyArrEndIndex, setHeartrateWeeklyArrEndIndex] = useState<number>(0);
  const [formattedHeartrateDailyArr, setFormattedHeartrateDailyArr] = useState<any>([]);
  const [formattedHeartrateWeeklyArr, setFormattedHeartrateWeeklyArr] = useState<any>([]);


  const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>(null)
  const [chartOptions, setChartOptions] = useState<any>(defaultChartData)

  const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
  const [avgType, setAvgType] = useState<string | null>('')
  const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

  const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
  const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);

  // values for custom range
  const [startDateValue, setStartDateValue] = useState<Date | any>(null)
  const [endDateValue, setEndDateValue] = useState<Date | any>(null)

  // values for dates for weekly and month we agreed to get from the API, the end date for those 2 values will be today
  const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
  const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)

  // this track what date was selected when arrows were used. this one is to track the date when arrows where used for Today
  const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
  // true if right arrow clicked for daily, false if left arrow clicked, null if none of the arrows was clicked
  const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)

  // those 2 states will track the date range, when arrows buttons were used when a range fitler was selected (past week, custom etc)
  const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
  const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)

  const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)

  // Number of days to show on weekly chart
  const numOfWeeklyDays = 28

  ////
  // UseEffects
  ////

  // This sets the filter for all charts to weekly filter if a user is selected and no filter is currently selected
  useEffect(() => {
    if (selectedUser && !selectedFilter) {
      setSelectedFilter('daily')
    }
  }, [selectedUser])


  // Function to check if the data is of type DailyHeartRateData
  function isDailyHeartRateData(data: any): data is HeartRateData {
    return typeof data === 'object' && data !== null && 'timeoffsetheartratesamples' in data;
  }

  // This sets up and formats the array of panel data
  useEffect(() => {
    if (userDetailData) {

      // Use this function when mapping formattedHeartrateDailyArr
      const heartrateDaysArr = Object.keys(userDetailData.heart_rate.daily).map(key => {
        const data = userDetailData.heart_rate.daily[key];
        return { date: key, data: isDailyHeartRateData(data) ? data : null };
      });

      const selectedDayIndex = heartrateDaysArr.length - 1
      const selectedDailyDate = heartrateDaysArr[selectedDayIndex].date
      const selectedWeekEndIndex = heartrateDaysArr.length - 1
      const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays


      // Adding 1 since the slice method is exclusive
      const weeklyData = heartrateDaysArr.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)

      // Set up daily data
      setFormattedHeartrateDailyArr(heartrateDaysArr)
      setHeartrateDailyArrIndex(selectedDayIndex)

      // This is for the display of the date in the panel for daily filter
      setSingleDayArrowsUsed(selectedDailyDate)

      // Set up weekly data
      setFormattedHeartrateWeeklyArr(weeklyData)
      setHeartrateWeeklyArrStartIndex(selectedWeekStartIndex)
      setHeartrateWeeklyArrEndIndex(selectedWeekEndIndex)

    }
  }, [userDetailData])

  // Setting up chart for non custom filters
  useEffect(() => {

    if (userDetailData) {

      if (!selectedFilter || formattedHeartrateDailyArr.length === 0 || heartrateDailyArrIndex === null
      ) {
        setChartOptions(defaultChartData) // initialize the state variable

      } else if (selectedFilter === 'daily') {

        const dailyData = formattedHeartrateDailyArr[heartrateDailyArrIndex]?.data;
        const dailyDate = formattedHeartrateDailyArr[heartrateDailyArrIndex]?.date

        setSingleDayArrowsUsed(dailyDate)

        const categories = isDailyHeartRateData(dailyData)
          ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) =>
            new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
          : [];

        const series = isDailyHeartRateData(dailyData)
          ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) => item?.value)
          : [];

        const chartDataForDaily = {
          ...defaultChartData,
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories
          },
          series: [
            {
              name: 'Heartrate',
              data: series,
            },
          ],
        }

        setChartOptions(chartDataForDaily)
      } else if (selectedFilter === 'weekly' && heartrateWeeklyArrStartIndex !== null && heartrateWeeklyArrEndIndex !== null) {
        const weeklyData = formattedHeartrateDailyArr.slice(heartrateWeeklyArrStartIndex, heartrateWeeklyArrEndIndex + 1)

        // This is for the display of hte dates in the panel for weekly filter
        setFilterButtonStartDate(weeklyData[0].date)
        setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)

        const categories = Object.values(weeklyData).map((item: any) => item.date)
        const seriesAvgHeartrate = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.averageheartrateinbeatsperminute
          } else {
            return null
          }
        })

        const seriesMaxHeartrate = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.maxheartrateinbeatsperminute
          } else {
            return null
          }
        })

        const seriesRestingHeartrate = Object.values(weeklyData).map((item: any) => {
          if (item.data) {
            return item?.data?.restingheartrateinbeatsperminute
          } else {
            return null
          }
        })

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              }
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: categories,
          },
          series: [
            {
              name: 'Average Heart Rate In Beats Per Minute',
              data: seriesAvgHeartrate,
            },
            {
              name: 'Resting Heart Rate In Beats Per Minute',
              data: seriesRestingHeartrate,
            },
            {
              name: 'Max Heart Rate In Beats Per Minute',
              data: seriesMaxHeartrate,
            },
          ],
        }
        setChartOptions(multidayChartData)
      }
      else if (selectedFilter === 'monthly') {

        const monthlyHeartRateAvgValues = Object.values(userDetailData.heart_rate.monthly)
        const monthlyHeartRateAvgKeys = Object.keys(userDetailData.heart_rate.monthly)

        setFilterButtonStartDate(monthlyHeartRateAvgKeys[0])
        setFilterButtonEndDate(monthlyHeartRateAvgKeys[monthlyHeartRateAvgKeys.length - 1])

        const multidayChartData = {
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },
          xAxis: {
            ...defaultChartData.xAxis,
            categories: monthlyHeartRateAvgKeys,
          },
          series: [
            {
              name: 'Resting Heart Rate In Beats Per Minute',
              data: monthlyHeartRateAvgValues
            }
          ],
        }
        setChartOptions(multidayChartData)

      }

    } else {
      setChartOptions(defaultChartData)
    }
  }, [formattedHeartrateDailyArr, heartrateWeeklyArrStartIndex, heartrateWeeklyArrEndIndex, heartrateDailyArrIndex, selectedFilter, singleDayArrowsUsed])

  // API CALL. get data when custom filter is selected
  // useEffect(() => {

  //   if (selectedUser && selectedFilter && selectedFilter === 'custom' && startDateValue && endDateValue && numberOfUnits && avgType && btnPressed) {

  //     setBtnPressed(false)

  //     axios
  //       .post(process.env.REACT_APP_API_URL + 'filter-one', {
  //         firstName: selectedUser.first_name,
  //         lastName: selectedUser.last_name,
  //         email: selectedUser.email,
  //         panelType: 'heartrate',
  //         filterType: selectedFilter,
  //         customDates: {
  //           startDate: format(startDateValue, 'yyyy-MM-dd'),
  //           endDate: format(endDateValue, 'yyyy-MM-dd'),
  //         },
  //         avgType: avgType,
  //         numberOfUnits: numberOfUnits
  //       })
  //       .then((response) => {
  //         setCustomHeartrateData(response.data.data.custom_resting_heart_rate_averages)
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar('Something went wrong, and heartrate data did not load', { variant: 'error' })
  //       })
  //   }
  // }, [selectedFilter, selectedUser, startDateValue, endDateValue, numberOfUnits, avgType, btnPressed, enqueueSnackbar])


  // Set up chart for custom
  // useEffect(() => {

  //   if (customHeartrateData) {
  //     if (selectedFilter === 'custom') {


  //       let restingHeartRateAverages = [...customHeartrateData]

  //       const multidayChartData = {
  //         title: {
  //           text: '',
  //         },
  //         plotOptions: {
  //           series: {
  //             label: {
  //               connectorAllowed: false,
  //             },
  //           },
  //         },
  //         xAxis: {
  //           ...defaultChartData.xAxis,
  //           categories: restingHeartRateAverages.map((data) => data.timeframe),
  //         },
  //         series: [
  //           {
  //             name: 'Resting Heart Rate In Beats Per Minute',
  //             data: restingHeartRateAverages.map((data) => data.custom_resting_heart_rate_avg),
  //           }
  //         ],
  //       }
  //       setChartOptions(multidayChartData)

  //     }
  //   }

  // }, [customHeartrateData, selectedFilter])

  ////
  // end of UseEffects
  ////


  // This monitors the display of the chart arrows
  useEffect(() => {

    if (userDetailData) {
      const lengthOfHeartrateDataArr = Object.keys(userDetailData.heart_rate.daily).length

      if (selectedFilter === 'daily') {
        if ((heartrateDailyArrIndex >= (lengthOfHeartrateDataArr - 1))) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if ((heartrateDailyArrIndex <= 0)) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }
      else if (selectedFilter === 'weekly') {
        if (heartrateWeeklyArrEndIndex >= (lengthOfHeartrateDataArr - 1)) {
          setDisplayRightArrow(false)
        } else {
          setDisplayRightArrow(true)
        }

        if (heartrateWeeklyArrStartIndex <= 0) {
          setDisplayLeftArrow(false)
        } else {
          setDisplayLeftArrow(true)
        }
      }

    }

  }, [heartrateDailyArrIndex, heartrateWeeklyArrStartIndex, heartrateWeeklyArrEndIndex, displayLeftArrow, displayRightArrow])

  const handleGetNext = () => {


    if (userDetailData) {
      const lengthOfHeartrateDataArr = Object.keys(userDetailData.heart_rate.daily).length

      if (selectedFilter === 'daily') {

        if ((heartrateDailyArrIndex + 1) <= lengthOfHeartrateDataArr - 1) {
          setHeartrateDailyArrIndex(heartrateDailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      } else if (selectedFilter === 'weekly') {

        if ((heartrateWeeklyArrEndIndex + numOfWeeklyDays) <= lengthOfHeartrateDataArr - 1) {
          setHeartrateWeeklyArrEndIndex(heartrateWeeklyArrEndIndex + numOfWeeklyDays);
          setHeartrateWeeklyArrStartIndex(heartrateWeeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setHeartrateWeeklyArrEndIndex(lengthOfHeartrateDataArr - 1);
          setHeartrateWeeklyArrStartIndex((lengthOfHeartrateDataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  const handleGetPrevious = () => {

    if (userDetailData) {

      if (selectedFilter === 'daily') {
        if ((heartrateDailyArrIndex - 1) >= 0) {
          setHeartrateDailyArrIndex(heartrateDailyArrIndex - 1);
          setDisplayLeftArrow(true);
        }
        else {
          setDisplayLeftArrow(false);
          console.log("Reached beginning of data set")
        }

      } else if (selectedFilter === 'weekly') {
        if ((heartrateWeeklyArrStartIndex - numOfWeeklyDays) >= 0) {
          setHeartrateWeeklyArrStartIndex(heartrateWeeklyArrStartIndex - numOfWeeklyDays);
          setHeartrateWeeklyArrEndIndex(heartrateWeeklyArrEndIndex - numOfWeeklyDays);

          setDisplayLeftArrow(true)
        } else {
          setHeartrateWeeklyArrStartIndex(0);
          setHeartrateWeeklyArrEndIndex(numOfWeeklyDays);

          setDisplayLeftArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  return (
    <>

      {!selectedUser ? (
        <Typography variant="body2">Please search for a user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            customRangeStartDate={startDateValue}
            customRangeEndDate={endDateValue}
            dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
            dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
            singleDay={singleDayArrowsUsed}
          />

          <ChartFilterButtons
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setSingleDayArrowsUsed={setSingleDayArrowsUsed}
            setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
            setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
            setStartDateValue={setStartDateValue}
            setEndDateValue={setEndDateValue}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            setSingleDayDecrementDays={setSingleDayDecrementDays}
            setDateRangeDecrementDays={setDateRangeDecrementDays}
            setFilterButtonStartDate={setFilterButtonStartDate}
            setFilterButtonEndDate={setFilterButtonEndDate}
            setChartData={setChartOptions}
            showDailyFilter={true}
            showWeeklyFilter={true}
            showMonthlyFilter={true}
            showCustomFilter={false}
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            avgType={avgType}
            setAvgType={setAvgType}
            btnPressed={btnPressed}
            setBtnPressed={setBtnPressed}
          />

          <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (

              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item={true} xs={10}>
              <HeartrateChart chartOptions={chartOptions} />
            </Grid>

            {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )
            }
          </Grid>
        </>
      )}
    </>
  )
}

export default HeartratePanelContent