import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Dispatch, SetStateAction, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface IProps {
  selectedFilter: IChartFilter | null
  setSelectedFilter: Dispatch<SetStateAction<IChartFilter | null>>
  setSingleDayArrowsUsed: Dispatch<SetStateAction<string | null>>
  setDateRangeArrowsBeginDate: Dispatch<SetStateAction<string | null>>
  setDateRangeArrowsEndDate: Dispatch<SetStateAction<string | null>>
  setStartDateValue: Dispatch<SetStateAction<Date | null>>
  setEndDateValue: Dispatch<SetStateAction<Date | null>>
  startDateValue: Date | null
  endDateValue: Date | null
  setSingleDayDecrementDays: Dispatch<SetStateAction<boolean | null>>
  setDateRangeDecrementDays: Dispatch<SetStateAction<boolean | null>>
  setFilterButtonStartDate: Dispatch<SetStateAction<string | null>>
  setFilterButtonEndDate: Dispatch<SetStateAction<string | null>>
  setChartData?: any
  showDailyFilter: Boolean | null
  showWeeklyFilter: Boolean | null
  showMonthlyFilter: Boolean | null
  showCustomFilter: Boolean | null
  numberOfUnits: number | null
  setNumberOfUnits: Dispatch<SetStateAction<number | null>>
  avgType: string | null
  setAvgType: Dispatch<SetStateAction<string | null>>
  btnPressed: Boolean | null
  setBtnPressed: Dispatch<SetStateAction<boolean | null>>
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const ChartFilterButtons = (props: IProps) => {
  const {
    selectedFilter,
    setSelectedFilter,
    setSingleDayArrowsUsed,
    setDateRangeArrowsBeginDate,
    setDateRangeArrowsEndDate,
    setStartDateValue,
    setEndDateValue,
    startDateValue,
    endDateValue,
    setSingleDayDecrementDays,
    setDateRangeDecrementDays,
    setFilterButtonStartDate,
    setFilterButtonEndDate,
    setChartData,
    showDailyFilter,
    showWeeklyFilter,
    showMonthlyFilter,
    showCustomFilter,
    numberOfUnits,
    setNumberOfUnits,
    avgType,
    setAvgType,
    btnPressed,
    setBtnPressed
  } = props


  let dailyFilter;
  let weeklyFilter;
  let monthlyFilter;
  let customFilter

  const handleNonCustomClick = (filterValue: IChartFilter) => {
    setSelectedFilter(filterValue)

    setSingleDayArrowsUsed(null)
    setDateRangeArrowsBeginDate(null)
    setDateRangeArrowsEndDate(null)

    setStartDateValue(null)
    setEndDateValue(null)

    if (filterValue === 'daily') {
      setDateRangeDecrementDays(null)
      setFilterButtonStartDate(null)
      setFilterButtonEndDate(null)
    }
    if (filterValue === 'weekly' || filterValue === 'monthly') {
      setSingleDayDecrementDays(null)
      if (setChartData) {
        setChartData(defaultChartData)
      }
    }
  }

  const handleCustomClick = () => {
    setSelectedFilter('custom')

    setDateRangeDecrementDays(null)
    setSingleDayDecrementDays(null)
    setFilterButtonStartDate(null)
    setFilterButtonEndDate(null)

    if (setChartData) {
      setChartData(defaultChartData)
    }
  }

  const handleTextFieldChange = (e: any) => {
    setNumberOfUnits(parseInt(e.target.value))

  }


  if (showDailyFilter) {

    dailyFilter = <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'daily' ? 'outlined' : 'text'}
      onClick={() => handleNonCustomClick('daily')}
      sx={{
        marginRight: '10px',
        height: '40px',
        pointerEvents: selectedFilter === 'daily' ? 'none' : 'auto',
        color: selectedFilter === 'daily' ? grey[600] : 'secondary',
      }}
    >
      Daily
    </Button>
  }

  if (showWeeklyFilter) {

    weeklyFilter = <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'weekly' ? 'outlined' : 'text'}
      onClick={() => handleNonCustomClick('weekly')}
      sx={{ marginRight: '10px', pointerEvents: selectedFilter === 'weekly' ? 'none' : 'auto', color: selectedFilter === 'weekly' ? grey[600] : 'secondary' }}
    >
      Weekly
    </Button>
  }

  if (showMonthlyFilter) {
    monthlyFilter = <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'monthly' ? 'outlined' : 'text'}
      onClick={() => handleNonCustomClick('monthly')}
      sx={{ marginRight: '10px', pointerEvents: selectedFilter === 'monthly' ? 'none' : 'auto', color: selectedFilter === 'monthly' ? grey[600] : 'secondary' }}
    >
      monthly
    </Button>
  }

  if (showCustomFilter) {
    customFilter = <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'custom' ? 'outlined' : 'text'}
      onClick={() => handleCustomClick()}
      sx={{ marginRight: '10px' }}
    >
      Custom
    </Button>
  }

  return (
    <Grid container={true} item={true} xs={12} justifyContent="center">

      {dailyFilter}
      {weeklyFilter}
      {monthlyFilter}
      {customFilter}

      {selectedFilter === 'custom' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDateValue}
            onChange={(newValue) => {
              setStartDateValue(newValue)
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />

          <Typography variant="body2" sx={{ margin: '0 10px', alignSelf: 'center' }}>
            to
          </Typography>

          <DatePicker
            label="End Date"
            value={endDateValue}
            onChange={(newValue) => {
              setEndDateValue(newValue)
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      )}

      {
        selectedFilter === 'custom' && (
          <TextField
            id="filled-number"
            label="Number of Units"
            type="number"
            variant="outlined"
            value={numberOfUnits}
            onChange={
              handleTextFieldChange
            }
            sx={{ minWidth: 50 }}
            size='small'
          />
        )
      }



      {
        selectedFilter === 'custom' && (

          <FormControl variant='outlined' size='small' sx={{ minWidth: 160 }}>
            <InputLabel id="custom-simple-select-label">Type of Average</InputLabel>
            <Select
              labelId="custom-simple-select-label"
              id="custom-simple-select"
              value={avgType}
              label="Average Type"
              onChange={(newValue) => {
                setAvgType(newValue.target.value)
              }}

            >
              <MenuItem value={'month'}>Month</MenuItem>
              <MenuItem value={'week'}>Week</MenuItem>
              <MenuItem value={'day'}>Day</MenuItem>
            </Select>
          </FormControl>


        )
      }

      {selectedFilter === 'custom' && (

        <Button
          size="small"
          color="secondary"
          variant={selectedFilter === 'custom' ? 'outlined' : 'text'}
          onClick={() => setBtnPressed(true)}
          sx={{ marginRight: '10px' }}
        >
          Submit
        </Button>
      )}
    </Grid>
  )
}

export default ChartFilterButtons
