import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { format, addDays, subDays, startOfWeek, endOfWeek, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { number } from 'yup';
import { useNavigate } from 'react-router-dom';


export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime())

  const dates = []

  while (date <= endDate) {
    dates.push(addDays(new Date(date), 1))
    date.setDate(date.getDate() + 1)
    //console.log("Date: ", date)
  }

  return dates
}

export const getDateRanges = () => {

  const today = new Date();

  const getWeekRange = () => {
    const start_date = startOfWeek(today);
    const end_date = endOfWeek(today);

    const start = start_date.toLocaleDateString('en-CA')
    const end = end_date.toLocaleDateString('en-CA')

    return { start, end };
  }

  const getMonthRange = () => {
    const start_date = startOfMonth(today);
    const end_date = endOfMonth(today);

    const start = start_date.toLocaleDateString('en-CA')
    const end = end_date.toLocaleDateString('en-CA')

    return { start, end };
  }

  return {
    getWeekRange,
    getMonthRange
  };

}


export const getStartDateEndDateBasedOnFilter = (selectedFilter: String): {
  start_date: string,
  end_date: string

} => {

  const { getWeekRange, getMonthRange } = getDateRanges();

  let start_date: string;
  let end_date: string;

  if (selectedFilter === 'daily') {

    start_date = new Date().toLocaleDateString('en-CA');
    end_date = start_date



  } else if (selectedFilter === 'weekly') {

    const { start, end } = getWeekRange();
    start_date = start;
    end_date = end;



  } else if (selectedFilter === 'monthly') {

    const { start, end } = getMonthRange();
    start_date = start;
    end_date = end;

  } else {

    start_date = '';
    end_date = '';

  }


  return { start_date, end_date }
}


export const setAccessTokenCookie = (accessToken: string): void => {
  // Removed HttpOnly attribute because it can only be set server-side.
  document.cookie = `access_token=${accessToken}; Secure; SameSite=Strict;`;
  localStorage.setItem('access_token', accessToken);
}

export const removeAccessTokenCookie = (): void => {
  document.cookie = `access_token=; Max-Age=0; Secure; SameSite=Strict;`;
  localStorage.removeItem('access_token')
}

export const setRefreshTokenCookie = (refreshToken: string): void => {
  // Removed HttpOnly attribute because it can only be set server-side.
  localStorage.setItem('refresh_token', refreshToken);
}


export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if(process.env.REACT_APP_MM_AUTH_REFRESH_URL){

    if (refreshToken) {
      const response = await axios.post(process.env.REACT_APP_MM_AUTH_REFRESH_URL, {
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ refresh: refreshToken })
      })      
      
      if (response.data.access) {              
          setAccessTokenCookie(response.data.access)            
      }

  }
  } else {
    console.error("Auth Refresh URL is not defined")
  }

}


export const useCheckAuthentication = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  function getEnvVariable(key: string): string {
    const value = process.env[key];
    if (typeof value === 'string') {
      return value;
    }
    throw new Error(`Environment variable ${key} is not set`);
  }
  useEffect(() => {

    function getEnvVariable(key: string): string {
      const value = process.env[key];

      if (typeof value === 'string') {
        return value;
      }
      throw new Error(`Environment variable ${key} is not set`);
    }

    const checkAuthStatus = async () => {
      try {
        console.log("Checking Auth Status...")
        const response = await axios.get(getEnvVariable("REACT_APP_MM_AUTH_VALIDATE_URL"), {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          }
        });
        if (response.status === 200) {
          setIsLoggedIn(true);
          console.log("Auth Status has been confirmed...")
        } else {
          setIsLoggedIn(false);
          navigate("/")
          
        }
      } catch (error) {
        setIsLoggedIn(false);
        navigate("/")
      }
    };

    checkAuthStatus();
  }, []);

  return [isLoggedIn, setIsLoggedIn];
};

